import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './style.scss';

const Footer = () => (
	<StaticQuery
		query={graphql`
			query SocialQuery {
				site {
					siteMetadata {
						siteUrl
					}
				}
			}
		`}
		render={data => (
			<footer className="footer center">
				<div className="content has-text-centered">
					<p>Designed by <a href="https://craftystudio.pl">CRAFTY.STD</a> | {data.site.siteMetadata.siteUrl}</p>
				</div>
			</footer>
		)}
	/>
);

export default Footer;
